import { FC } from 'react';
import User from '../../../models/User';
import Tooltip from '../Tooltip';
import ProfileInitials from './ProfileInitials';
import { dataAttributeProps } from '../../../utils/ComponentUtils';

type ProfileImageProps = {
  user?: User;
  role?: string;
  size?: ImageSize;
  withMargin?: boolean;
  className?: string;
};

export enum ImageSize {
  XS,
  S,
  M,
}

const ProfileImage: FC<ProfileImageProps> = (props) => {
  const { user, size, withMargin, className = '', role } = props;
  let sizeCss = 'w-10 h-10 text-dpm-14';
  const marginCss = withMargin ? '-ml-2' : '';

  switch (size) {
    case ImageSize.XS:
      sizeCss = 'min-w-6 w-6 h-6 text-xs';
      break;
    case ImageSize.S:
      sizeCss = 'min-w-8 w-8 h-8 text-dpm-12';
      break;
    default:
      sizeCss = 'min-w-10 w-10 h-10 text-dpm-14';
      break;
  }

  return (
    <Tooltip
      text={
        user?.firstName &&
        user?.lastName && (
          <>
            <div>
              {user.firstName} {user.lastName}
            </div>
            {!!role && <div>{role}</div>}
          </>
        )
      }
    >
      {(tooltip) => (
        <div {...tooltip} className="flex w-fit flex-col justify-center">
          {!user?.profileImageUrl ? (
            <ProfileInitials
              firstName={user?.firstName ?? ''}
              lastName={user?.lastName ?? ''}
              id={user?.id ?? ''}
              className={className}
              marginCss={marginCss}
              sizeCss={sizeCss}
              {...dataAttributeProps(props)}
            />
          ) : (
            <img
              alt={user?.fullName}
              src={user?.profileImageUrl}
              className={`${className} ${marginCss} rounded-full bg-white transition-all duration-300 ease-in-out ${sizeCss} inline-flex cursor-default justify-center`}
            />
          )}
        </div>
      )}
    </Tooltip>
  );
};

export default ProfileImage;
